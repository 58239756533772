import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Dates from "../components/Dates";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import Speakers from "../components/Speakers";
import Call from "../components/Call";
import Countdown from "../components/Countdown";
import TrackDetails from "../components/TrackDetails";
import AdvisoryCommittee from "../components/AdvisoryCommittee";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <AboutUs />
      <Countdown />
      <TrackDetails />
      <Dates />
      <Speakers />
      {/* <Call /> */}
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
