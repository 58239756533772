import React from "react";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

const AdvisoryCommittee = () => {
  const committeeData = {
    "Conference General Chair": [
      {
        name: "Prof. (Dr.) Harish Mittal",
        institution: "Principal, BMIET, India",
      },
    ],
    "Technical Program Chair": [
      {
        name: "Dr. Naveen Garg",
        institution: "Director, ANR (P) Pvt. Ltd., India",
      },
    ],
    "Conference Secretary": [
      { name: "Dr. Pertik Garg", institution: "Principal, SVIET" },
    ],
    Convenor: [{ name: "Ms. Lucy Garg", institution: "PIET, Panipat, India" }],
    "Technical Program Co-Chair": [
      {
        name: "Dr. Amanpreet Singh",
        institution:
          "Anand College of Engineering & Management, Kapurthala, India",
      },
      {
        name: "Prof. (Dr.) Vishal Jain",
        institution: "Dean Academics, BMIET, India",
      },
    ],
    "Organizing Committee Member": [
      {
        name: "Dr. Pradeep Chouksey",
        institution: "HoD, Central University Himachal Pradesh, Kangra, India",
      },
      {
        name: "Dr. Gurminder Kaur",
        institution: "Incharge, Deptt of CSE, BMIET, Sonepat, India",
      },
    ],
    "International Program Committee Members": [
      {
        name: "Prof. (Dr.) Valentina Emilia Balas",
        institution: "Aurel Vlaicu University of Arad, România",
      },
      {
        name: "Dr. Zdzislaw Polkowski",
        institution:
          "The Karkonosze State University of Applied Sciences, Poland",
      },
      {
        name: "Dr. Israel Koren",
        institution: "University of Massachusetts, Amherst, MA",
      },
      {
        name: "Dr. Hamid R. Arabnia",
        institution: "University of Georgia, USA",
      },
      { name: "Dr. Mahwish Riyaz", institution: "University of Auckland, NZ" },
      { name: "Prof. Brij B Gupta", institution: "Asia University, Taiwan" },
      {
        name: "Dr. Kamal Gulati",
        institution: "Professor, Stratford University, USA",
      },
      {
        name: "Dr. Anuraj Panwar",
        institution: "St. Francis Xavier University, Canada",
      },
      { name: "Dr. Jean Paul Lam", institution: "Waterloo University, Canada" },
      { name: "Dr. Thomos Loo", institution: "CEO, Goodlabs Studio, Canada" },
      {
        name: "Dr. Ajeet Kaushik",
        institution: "FIU Herbert Wertheim College of Medicine, USA",
      },
      {
        name: "Dr. Vishal Dattana",
        institution: "Middle East College, Muscat, Oman",
      },
      {
        name: "Dr. Nagham Mahmood Aljamali",
        institution: "College of Science for Women, Babylon, Iraq",
      },
      {
        name: "Dr. Ramani Kannan",
        institution: "Universiti Teknologi PETRONAS, Malaysia",
      },
      {
        name: "Dr. Kamel Hussein Rahouma",
        institution: "Minia University, Minia, Egypt",
      },
      {
        name: "Dr. Adrian Nicolae Branga",
        institution: "Lucian Blaga University of Sibiu, Romania",
      },
      {
        name: "Dr. Aidy Ali",
        institution:
          "National Defence University of Malaysia, Kuala Lumpur, Malaysia",
      },
      {
        name: "Dr. Jian-Nong Cao",
        institution: "Hong Kong Polytechnic University, Hong Kong",
      },
      { name: "Dr. Xiao-Zhigao", institution: "Aalto University, Finland" },
      {
        name: "Dr. Dharam Singh",
        institution: "Namibia University of Science and Technology, Namibia",
      },
      {
        name: "Dr. Thomas Hanne",
        institution: "University of Applied Sciences, Switzerland",
      },
      {
        name: "Dr. Phuc",
        institution:
          "University of Information Technology, Ho Chi Minh City, Vietnam",
      },
      { name: "Dr. Mariofanna Milanova", institution: "UALR, USA" },
      {
        name: "Dr. Clemens Grelck",
        institution: "University of Amsterdam, USA",
      },
      {
        name: "Dr. Tayfun Dede",
        institution: "Karadeniz Technical University, Turkey",
      },
      {
        name: "Dr. Christopher Mcmahon",
        institution: "University of Waterloo, Canada",
      },
      {
        name: "Dr. Eng. Ahmed Kadhim Hussein",
        institution: "Babylon University, Iraq",
      },
      {
        name: "Dr. Nagham Mahmood Aljamali",
        institution: "Professor, Chemistry Department, Iraq",
      },
      {
        name: "Dr. Nour Abd Alrazzak Abd Allattif",
        institution: "College of Science for Women, Babylon, Iraq",
      },
      {
        name: "Dr. Donald Mcgilivary",
        institution: "Conestoga College, Guelph, Canada",
      },
      { name: "Dr. Haider Raja", institution: "University of Essex, UK" },
      {
        name: "Dr. Mohamed Abdel Fatah Ashabrawy",
        institution: "Prince Sattam bin AbdulAziz University, Saudi Arabia",
      },
      { name: "Dr. Tanupreet Singh", institution: "Yonsen University, Canada" },
      {
        name: "Dr. Sorina Chircu",
        institution: "Politehnica University of Bucharest, Romania",
      },
      {
        name: "Dr. Ummi Naiemah Saraih",
        institution: "University Malaysia Perlis, Malaysia",
      },
      {
        name: "Dr. Mohd Norfian Alifiah",
        institution: "Azman Hashim International Business School, Malaysia",
      },
      {
        name: "Dr. Maddula Prasad",
        institution: "School of CS & N, Dilla University, Ethiopia",
      },
      {
        name: "Dr. Arfat Ahmad Khan",
        institution: "Khon Kaen University, Thailand",
      },
    ],
    "National Program Committee Members": [
      {
        name: "Dr. Madhulika Dixit",
        institution: "Former Resource Person, Azim Premji Foundation, India",
      },
      {
        name: "Prof. (Dr.) Pradeep Kumar Bhatia",
        institution: "Ex Professor, GJUS&T, Hisar, India",
      },
      {
        name: "Dr. Parvinder Singh",
        institution: "Professor CSE, DCRUST University, Sonepat, India",
      },
      {
        name: "Dr. Nageswara Rao Moparthi",
        institution:
          "Velagapudi Ramakrishna Siddhartha Engineering College, India",
      },
      {
        name: "Dr. Kanwaljit Kaur",
        institution: "SGGS College, Chandigarh, India",
      },
      {
        name: "Dr. S. Gunabal",
        institution: "Annamalai University, Tamil Nadu, India",
      },
      {
        name: "Dr. Yatu",
        institution:
          "BM Institute of Engineering and Technology, Sonepat, India",
      },
      {
        name: "Dr. Gurminder Kaur",
        institution:
          "BM Institute of Engineering and Technology, Sonepat, India",
      },
      {
        name: "Dr. Ashish Kumar",
        institution: "Lovely Professional University, Punjab, India",
      },
      {
        name: "Dr. Dipak Tukaram Tayade",
        institution: "Government Vidharbha Institute of Science, India",
      },
      {
        name: "Dr. Saroj Arora",
        institution: "Guru Nanak Dev University, Punjab, India",
      },
      {
        name: "Dr. Anjanapura Venkataramanaiah Raghu",
        institution: "FET, Jain Deemed University, India",
      },
      {
        name: "Dr. Mahip Manikalji Bartere",
        institution: "G H Raisoni University, Maharashtra, India",
      },
      {
        name: "Dr. P Pal Pandian",
        institution: "Christ University, Bengaluru, India",
      },
      {
        name: "Dr. Shrikant Jagdish Honade",
        institution: "G H Raisoni University, Maharashtra, India",
      },
      {
        name: "Dr. Udaykumar Laxman Naik",
        institution: "KLE Dr. M S Sheshgiri College, Belagavi, India",
      },
      { name: "Dr. P K Singh", institution: "Director IIM, Trichy, India" },
      {
        name: "Dr. R K Singh",
        institution: "Vice Chancellor, PPU, Patna, India",
      },
      { name: "Dr. Pramod Pathak", institution: "IIT ISM, Dhanbad, India" },
      { name: "Dr. Vikas Chawla", institution: "Dean Academics, PTU, India" },
      {
        name: "Dr. Arun Kumar Rana",
        institution: "Galgotia College of Engineering and Technology, India",
      },
      {
        name: "Dr. Pankaj Dadheech",
        institution: "Swami Keshvanand Institute of Technology, Jaipur, India",
      },
      {
        name: "Dr. Raman Chadha",
        institution: "Chandigarh University, Punjab, India",
      },
    ],
  };

  return (
    <div>
      <div className="container py-4">
        <div
          className="section-title text-center position-relative pb-3 mb-2 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Committees</h5>
          <h2 className="mb-0">Committee Members</h2>
        </div>
        <div className="row">
          {Object.keys(committeeData).map((category, categoryIndex) => (
            <div key={categoryIndex} className="row">
              <h4 className="my-4 text-primary">{category}</h4>
              {committeeData[category].map((member, memberIndex) => (
                <div key={memberIndex} className="col-md-4 mb-3">
                  <h5>◆ {member.name}</h5>
                  <p>{member.institution}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default AdvisoryCommittee;
