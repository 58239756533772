import React from "react";

const Dates = ({ dateRef }) => {
  return (
    <div ref={dateRef} pt-lg-5>
      <div className="container-fluid facts py-5 pt-lg-2 mt-lg-5 pb-5">
        <div className="container py-5 pt-lg-0">
          <div
            className="section-title text-center position-relative pb-3 mb-2 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Timeline</h5>
            <h1 className="mb-0">Important Dates</h1>
          </div>
          <div className="row gx-0">
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.1s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-users text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Paper Submission Deadline</h5>
                  <h3 className="text-white mb-0" data-toggle="counter-up">
                    15th September 2024
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">
                    Authors Notified of Final Acceptance
                  </h5>
                  <h3 className="mb-0" data-toggle="counter-up">
                    30th September 2024
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-award text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">
                    Camera-Ready Paper Submissions and Copyrights Due
                  </h5>
                  <h3 className="text-white mb-0" data-toggle="counter-up">
                    10th October 2024
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-0 my-4 flex justify-content-center">
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">
                  Last date for Registration
                  </h5>
                  <h3 className="mb-0" data-toggle="counter-up">
                  15th October 2024
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-award text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">
                    Conference Date
                  </h5>
                  <h3 className="text-white mb-0" data-toggle="counter-up">
                    16-17 November 2024
                  </h3>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Dates;
