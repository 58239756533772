import React from "react";

export const SpeakerCard = ({ speaker }) => (
  <div className="col-lg-3 col-md-4 col-sm-6 my-4">
    <div className="card shadow-sm">
      <div className="overflow-hidden">
        <img className="card-img-top" src={speaker.image} alt={speaker.name} />
      </div>
      <div className="card-body text-center">
        <h5 className="card-title text-primary">{speaker.name}</h5>
        <p className="card-text">{speaker.designation}</p>
        <a className="text-danger" href={`mailto:${speaker.email}`}>
          {speaker.email}
        </a>
      </div>
    </div>
  </div>
);

const Speaker = () => {
  const conferenceData = {
    speakers: [
      {
        name: "Dr. Naresh Kumar",
        designation: "University of Nizwa, Sultanate of Oman",
        // email: "naresh@unizwa.edu.om",
        image: "https://www.icpids.com/assets/img/team-1.jpeg",
      },
      {
        name: "Dr. Chaman Verma",
        designation:
          "Department of Media and Educational Informatics, Faculty of Informatics, Eötvös Loránd University, 1053 Budapest, Hungary",
        // email: "chaman@inf.elte.hu",
        image: "https://www.icpids.com/assets/img/team-2.jpg",
      },
      {
        name: "Dr S B Goyal",
        designation:
          "Director, Faculty of Information Technology, City University of Malaysia",
        // email: "drsbgoyal@gmail.com",
        image: "https://www.icpids.com/assets/img/team-3.jpg",
      },
      {
        name: "Dr. Kusum Yadav",
        designation:
          "College of Computer Science and Engineering, University of Ha'il, Kingdom of Saudi Arabia",
        // email: "kusum.yadav@uoh.edu.sa",
        image: "https://www.icpids.com/assets/img/team-4.jpg",
      },
    ],
  };

  return (
    <div className="container my-3">
      <div
        className="section-title text-center position-relative pb-3 mb-2 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">
          Keynote Speakers
        </h5>
        {/* <h1 className="mb-0">Keynote Speakers</h1> */}
      </div>
      <div className="row">
        {conferenceData.speakers.map((speaker, index) => (
          <SpeakerCard key={index} speaker={speaker} />
        ))}
      </div>
    </div>
  );
};

export default Speaker;
