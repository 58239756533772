import React from "react";

const Countdown = () => {
  return (
    <div>
      <script src="https://cdn.logwork.com/widget/countdown.js"></script>
      <a
        href="https://logwork.com/countdown-timer"
        class="countdown-timer"
        data-timezone="Asia/Kolkata"
        data-textcolor="#49b5e7"
        data-date="2024-09-15 12:00"
        data-background="#49b5e7"
        data-digitscolor="#ffffff"
        data-unitscolor="#49b5e7"
      >
        Paper Submission Deadline
      </a>
    </div>
  );
};

export default Countdown;
